<template>
    <div id="ap">
        <a-layout>
            <div class="content-header">
                <a-affix :offset-top="0" style="width: 100%;">
                    <a-row type="flex" justify="space-between">
                        <a-col span="8">
                            <common-page-btn @save="add"></common-page-btn>
                        </a-col>
                        <a-col span="16">
                            <a-row type="flex" align="middle" justify="end" :gutter="3">
                                <a-col span="6">
                                    <a-input v-model="param.keyword" allowClear search @pressEnter="doRefresh"
                                             @on-clear="doRefresh" placeholder="关键词查询"></a-input>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                </a-affix>

            </div>
        </a-layout>

        <a-layout>
            <a-layout-content>
                <a-table  :scroll="{x:'100%'}" :pagination="false" rowKey="id" :style="{background:'#FFF'}" size="small" border :columns="keys" ref="list" :loading="loading" :data-source="data.records">
                    <!--状态-->
                    <template slot-scope="row" slot="Status">
                        <template v-if="row.status==1">
                            <a-icon type="check-circle" class="text-success"/>
                        </template>
                        <template v-else>
                            <a-icon type="stop"/>
                        </template>
                    </template>
                    <!--操作-->
                    <template slot-scope="row" slot="action">
                        <a-tooltip title="编辑" v-has="'protocol:save'"><a-button shape="circle" type="link" icon="edit" @click="add(row)" size="small"></a-button></a-tooltip>
                        <a-tooltip title="删除" v-has="'protocol:del'"><a-button shape="circle" type="link" icon="delete" @click="del(row.id)"></a-button></a-tooltip>
                    </template>
                </a-table>

            </a-layout-content>
        </a-layout>
        <a-row>
            <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
                <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
                      @showSizeChange="setLimit" :current="data.current" />
            </a-col>
        </a-row>
        <a-back-top />
        <agree-form ref="agreeForm" @success="getList"></agree-form>
    </div>
</template>

<script>
    import agreeForm from "@/views/system/agree/agreeForm";
    export default {
        components:{agreeForm},
        data() {
            return {
                loading: false,
                data: {},
                groupList:[],
                param: {
                    page: 1,
                    limit: 20,
                    keyword: '',
                    type:''
                },
                keys: [
                    {title: '编号', dataIndex: "id", width: 100, align: 'center'},
                    {title: "标识", dataIndex: "protocol",width:220, align: 'center',tooltip:true,resizable:true},
                    {title: "名称", dataIndex: "title", align: 'left',tooltip:true,resizable:true},
                    {title: "更新时间", dataIndex: "updateTime", align: 'center', width: 180},
                    {title: "操作", scopedSlots: { customRender: 'action' }, align: "center", width: 100}
                ]
            }
        },
        created() {
            this.getList();
        },
        methods: {
            //添加修改页面
            add(row){
                this.$refs.agreeForm._show(row ? row : null);
            },
            //删除
            del(id){
                this.utils.confirm('删除后无法恢复，是否确认?').then(()=>{
                    this.http.delete("/platform/protocol/del/"+id).then(ret=>{
                        if(ret.code==200){
                            this.utils.success('操作成功！').then(()=>{
                                this.getList();
                            });
                        }
                    })
                })
            },
            doRefresh(){
                this.param.page = 1;
                this.getList();
            },
            // 获取数据
            getList() {
                this.utils.loading();
                this.loading = true;
                this.http.get('/platform/protocol/list', this.param).then(ret => {
                    this.data = ret.data;
                    this.loading = false;
                    // console.log(ret)
                }).catch(err=>{
                    this.loading = false;
                })
            },
            //设置每页的显示条数
            setLimit(current,limit) {
                this.param.page = 1;
                this.param.limit = limit;
                this.getList();
            },
            //页码切换
            getNext(page) {
                this.param.page = page;
                this.getList();
            },
        }
    }
</script>

<style scoped>

</style>
